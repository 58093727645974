import payload_plugin_NEPHq8jEUC from "D:/deploy/UploadHidden/20250327-P903.312/arora-front/satellite/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_Frd8gASYWa from "D:/deploy/UploadHidden/20250327-P903.312/arora-front/satellite/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_3Bi0E2Ktsf from "D:/deploy/UploadHidden/20250327-P903.312/arora-front/satellite/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_CrWB4n4PyO from "D:/deploy/UploadHidden/20250327-P903.312/arora-front/satellite/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_T5aD6w6QZh from "D:/deploy/UploadHidden/20250327-P903.312/arora-front/satellite/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_JA9PnUIOcc from "D:/deploy/UploadHidden/20250327-P903.312/arora-front/satellite/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_yxgjJ9XxnV from "D:/deploy/UploadHidden/20250327-P903.312/arora-front/satellite/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_kCUITUWXUN from "D:/deploy/UploadHidden/20250327-P903.312/arora-front/satellite/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_BKbTeWPbAR from "D:/deploy/UploadHidden/20250327-P903.312/arora-front/satellite/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "D:/deploy/UploadHidden/20250327-P903.312/arora-front/satellite/build-rest-c30c5e83-3564-470d-b1ad-ea829fd1c731/.nuxt/components.plugin.mjs";
import prefetch_client_5ZRYOjuei0 from "D:/deploy/UploadHidden/20250327-P903.312/arora-front/satellite/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import errorHandler_x1Y4n5NP4w from "D:/deploy/UploadHidden/20250327-P903.312/arora-front/satellite/build-rest-c30c5e83-3564-470d-b1ad-ea829fd1c731/plugins/errorHandler.ts";
import i18n_VfGcjrvSkj from "D:/deploy/UploadHidden/20250327-P903.312/arora-front/satellite/build-rest-c30c5e83-3564-470d-b1ad-ea829fd1c731/plugins/i18n.ts";
export default [
  payload_plugin_NEPHq8jEUC,
  revive_payload_client_Frd8gASYWa,
  unhead_3Bi0E2Ktsf,
  router_CrWB4n4PyO,
  payload_client_T5aD6w6QZh,
  navigation_repaint_client_JA9PnUIOcc,
  check_outdated_build_client_yxgjJ9XxnV,
  chunk_reload_client_kCUITUWXUN,
  plugin_vue3_BKbTeWPbAR,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5ZRYOjuei0,
  errorHandler_x1Y4n5NP4w,
  i18n_VfGcjrvSkj
]