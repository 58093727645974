
import * as aroraProviderRuntime$yTv_RFh7rnHfcLwhsWU7eXvyp9TPCSeYtY8HO0MaR1w from 'D:/deploy/UploadHidden/20250327-P903.312/arora-front/satellite/build-rest-c30c5e83-3564-470d-b1ad-ea829fd1c731/providers/aroraProvider.ts'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "aroraProvider",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['aroraProvider']: { provider: aroraProviderRuntime$yTv_RFh7rnHfcLwhsWU7eXvyp9TPCSeYtY8HO0MaR1w, defaults: {} }
}
        